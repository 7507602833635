._7ZOsUa_container {
  padding-left: var(--page-padding);
  padding-right: var(--page-padding);
}

.BOBUQq_footer {
  margin-top: var(--s-xl);
  font-size: var(--f-m);
  border-top: 1px solid #00000017;
  padding-top: 30px;
  padding-bottom: 24px;
}

.BOBUQq_item + .BOBUQq_item {
  margin-left: 20px;
}

.BOBUQq_item:last-of-type {
  width: 100%;
  margin-top: 12px;
  margin-left: 0;
}

@media (width >= 500px) {
  .BOBUQq_item:last-of-type {
    width: auto;
    margin-top: 0;
    margin-left: 20px;
  }
}

.BOBUQq_items {
  width: 100%;
  font-size: var(--f-m);
  flex-wrap: wrap;
  display: flex;
}

@media (width >= 400px) {
  .BOBUQq_items {
    width: auto;
  }
}

@media (width >= 600px) {
  .BOBUQq_code {
    display: inline-block !important;
  }
}

.BOBUQq_button {
  border: 3px solid var(--c-border);
  text-wrap: nowrap;
  border-radius: 5px;
  padding: 4px 8px;
  font-weight: 600;
}

._7k0AMq_burger {
  color: #000;
  cursor: pointer;
  z-index: var(--z-highest);
  margin: 6px -10px -10px;
  padding: 0 10px 10px 18px;
  font-weight: bold;
}

._7k0AMq_active ._7k0AMq_drawer {
  opacity: 1;
}

._7k0AMq_drawer {
  background-color: var(--page-background);
  color: #000;
  width: 100%;
  height: 100vh;
  z-index: var(--z-highest);
  opacity: 0;
  align-items: center;
  padding: 0;
  transition: opacity .225s cubic-bezier(0, 0, .2, 1) 2s;
  display: block;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
}

._7k0AMq_drawerMenu {
  text-align: center;
  width: 100%;
  font-size: var(--f-l);
  font-family: var(--f-family);
}

._7k0AMq_drawerMenuItem + ._7k0AMq_drawerMenuItem {
  margin-top: 38px;
}

._7k0AMq_closeIcon {
  font-size: var(--f-xl);
  margin-left: -12px;
  margin-right: -12px;
  padding-left: 12px;
  padding-right: 12px;
  position: absolute;
  top: 16px;
  right: 28px;
}

._7k0AMq_activeNavItem {
  border-bottom: 1px solid;
}

._7k0AMq_backgroundBlur {
  -webkit-tap-highlight-color: transparent;
  z-index: var(--z-lowest);
  background-color: #000000b3;
  position: fixed;
  inset: 0;
}

.MqZHCq_header {
  padding-top: 24px;
  padding-left: var(--page-padding);
  padding-right: var(--page-padding);
  grid-template-areas: "MqZHCq_claim MqZHCq_claim MqZHCq_burger"
                       "MqZHCq_type MqZHCq_type MqZHCq_type";
  display: grid;
}

@media (width >= 700px) {
  .MqZHCq_header {
    grid-template-areas: "MqZHCq_claim MqZHCq_type MqZHCq_burger";
  }
}

.MqZHCq_claim {
  font-size: var(--f-l);
  grid-area: MqZHCq_claim;
  margin-top: 0;
  margin-bottom: 0;
}

* {
  animation: .333s ease-out MqZHCq_slideInFromBottom;
}

.MqZHCq_largeType {
  font-size: var(--f-l);
  opacity: 0;
  grid-area: MqZHCq_type;
  margin-top: 22vh;
  animation-fill-mode: forwards;
}

@keyframes MqZHCq_slideInFromBottom {
  from {
    transform: translateY(12px);
  }

  to {
    opacity: 1;
    transform: translateY(0);
  }
}

@media (width >= 700px) {
  .MqZHCq_largeType {
    margin-top: auto;
    margin-left: 12.5vw;
    margin-right: 28vw;
  }
}

.MqZHCq_burger {
  text-align: right;
  grid-area: MqZHCq_burger;
}

.ZMO6Uq_wrapper {
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  display: flex;
  position: fixed;
  inset: 0;
}

@keyframes ZMO6Uq_rotating {
  from {
    transform: rotate(0);
  }

  to {
    transform: rotate(360deg);
  }
}

.ZMO6Uq_emoji {
  filter: grayscale(.5);
  font-size: var(--f-xl);
  animation: 2.33s cubic-bezier(.21, .51, .83, .67) infinite ZMO6Uq_rotating;
}
/*# sourceMappingURL=index.8ef0d2a1.css.map */
